import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { CLocaleSwitcherScreen } from 'ui'
import { GetServerSidePropsContext } from 'next'
import { serverSideValidateWww } from 'shared'
import { setCacheHeaders } from '../helpers/cache-config'

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const isWww = serverSideValidateWww({ context })

  if (!isWww || !context.locale) {
    return {
      notFound: true,
    }
  }

  setCacheHeaders(context.res)

  return {
    props: {
      ...(await serverSideTranslations(context.locale ?? '', ['common'])),
    },
  }
}

export default function LocaleSwitcher() {
  return <CLocaleSwitcherScreen />
}
